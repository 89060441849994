import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ParkBathSupport = () => (
  <Layout>
    <SEO title="Park Bath support" />
    <h1>Park Bath</h1>
    <p>
      Are you having issues with Park Bath? Then please send an email to: me
      [at] tomandrews [dot] co [dot] uk
    </p>
  </Layout>
)

export default ParkBathSupport
